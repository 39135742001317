import PageLayout from 'components/layout/PageLayout';
import Button from 'components/ui/button/Button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Modal from '../ui/modal/Modal';
import { useModalError } from './error-modal-context';



const ErrorModal: React.FC = () => {

    const { t } = useTranslation();
    const { error, removeError } = useModalError()

    const handleClick = () => {
        removeError();
    }

    return (
        <Modal
            isOpen={!!error}
            onRequestClose={handleClick}
            title={t('common:errors.modalDefaultTitle')}
            size={'sm'}
        >
            <div className="text-center bg-white">
                <PageLayout py="sm" >
                    <div className="flex flex-col items-center space-y-10">
                        <p className="text-xl">
                            {error && error.message}
                        </p>
                        <Button onClick={handleClick}>OK</Button>
                    </div>
                </PageLayout>

            </div>
        </Modal>
    )
}

export default ErrorModal;