import Link, { LinkProps } from 'next/link'
import React, { Children } from 'react'


export interface NavLinkProps extends LinkProps {
  children?: any;
  active?: boolean;
  activeClassName?: string;
}

const NavLink: React.FC<NavLinkProps> = ({ children, active, activeClassName, ...props }) => {
  // console.debug(children);
  // const router = useRouter()
  const child = Children.only(children)
  const childClassName = child.props.className || ''

  // pages/index.js will be matched via props.href
  // pages/about.js will be matched via props.href
  // pages/[slug].js will be matched via props.as
  const className =
    active ? `${childClassName} ${activeClassName}`.trim()
      : childClassName

  return (
    <Link passHref {...props}>
      {React.cloneElement(child, {
        className: className || null,
      })}
    </Link>
  )
}


export default NavLink

