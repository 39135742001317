import React, { useState } from 'react';
import { UserType } from '@model/dist/esm';
import { useTranslation } from 'next-i18next';
import { HiMenu } from 'react-icons/hi';
import useOnRouteChangeCompleteCallback from '../hooks/useOnRouteChangeCompleteCallback';
import { useNavbarHeader } from './NavbarHeaderContext';
import NavLink from './NavLink';
import Link from 'next/link'
import dynamic from 'next/dynamic'
const LangSwitcher = dynamic(() => import('./LangSwitcher').then(mod => mod.LangSwitcher));
import Image from '../ui/image/Image'
import { Routes } from 'lib/routes';

export interface NavBarProps {
    isLoggedIn: boolean;
    userType?: UserType;
    links: {
        label: string;
        link: string;
        active?: boolean;
        position?: 'left' | 'right';
    }[]
}

interface RightButtonsProps {
    isLoggedIn: boolean;
    mode: 'black' | 'white';
    isMobileMenuOpen: boolean;
}

const RightButtons: React.FC<RightButtonsProps> = ({ isLoggedIn, mode, isMobileMenuOpen }) => {
    const { t } = useTranslation()


    return (
        <div className="flex flex-col items-center space-y-6 lg:space-y-0 lg:items-baseline lg:flex-row ">
            {/* <Link passHref href={Routes.JOIN_TEACHER_TRIBE}>
                <a href="#" className={`px-4 py-3 mt-2 w-full text-center lg:w-auto leading-none ${(isMobileMenuOpen || mode === 'black') ? 'text-white bg-primary-500 hover:border-primary-600 hover:bg-primary-600' : 'bg-white'}  transition duration-200 border border-transparent rounded  lg:inline-block lg:mt-0 lg:ml-4 lg:px-8 `}>
                    {t('common:navbar.subscribe')}
                </a>
            </Link> */}
            <Link passHref href={Routes.OFFER_CREATE}>
                <a href="#" className={`px-4 py-3 mt-2 w-full text-center lg:w-auto leading-none ${(isMobileMenuOpen || mode === 'black') ? 'font-bold text-black bg-white border-black border-2 hover:bg-gray-300' : 'bg-white'}  transition duration-200 rounded  lg:inline-block lg:mt-0 lg:ml-4 lg:px-8 `}>
                    {t('common:navbar.postJob')}
                </a>
            </Link>
        </div>
    )
}



export const NavBar: React.FC<NavBarProps> = ({ isLoggedIn, userType, links }) => {

    const [isMobileMenuOpen, setMobileMenuIsOpen] = useState(false);
    const toggleMobileMenu = () => setMobileMenuIsOpen(!isMobileMenuOpen);

    const { isNavbarHeaderVisible, mode } = useNavbarHeader()

    // pour fermer le menu lors d'un changement de page
    useOnRouteChangeCompleteCallback({ callback: () => setMobileMenuIsOpen(false) })


    return (
        <>

            <nav className={`${isMobileMenuOpen ? 'fixed h-screen w-screen bg-white' : (isNavbarHeaderVisible ? 'relative bg-transparent' : 'sticky shadow-header bg-white')}  top-0 z-30  flex flex-wrap items-start lg:items-baseline justify-between px-5 pt-3 pb-3 lg:text-sm  lg:pb-0 lg:pt-0`}>
                <div className="flex self-center flex-shrink-0 mr-6 text-black lg:items-baseline">
                    <NavLink href="/">
                        <a>
                            <Image width={184} height={40} src="/images/logo/tt-logo.png" alt="Teacher Tribe Home" title="Teacher Tribe" />
                        </a>
                    </NavLink>
                </div>

                <div className="block lg:hidden" onClick={toggleMobileMenu}>
                    <button className={`flex items-center focus:outline-none ${isMobileMenuOpen ? 'text-primary-500' : 'text-black'}`}>
                        <HiMenu size="1.5em" />
                    </button>
                </div>
                <div className={`w-full ${isMobileMenuOpen ? 'block h-full mt-8' : 'hidden'} transition-all duration-150 flex-grow flex lg:flex flex-col items-center lg:flex-row lg:items-baseline lg:w-auto lg:ml-6 `}>
                    <div className=" lg:flex-grow">
                        {links.filter(l => l.position !== 'right').map(link => (
                            <NavLink key={link.label} href={link.link} active={link.active} activeClassName="navlink-active">
                                <a className="block mt-4 font-bold leading-none text-black duration-100 border-transparent lg:mr-8 lg:pt-6 lg:pb-5 lg:inline-block lg:mt-0 hover:text-black lg:border-b-4 hover:border-black">
                                    {link.label}
                                </a>
                            </NavLink>
                        ))}
                    </div>
                    <div className="text-center">
                        {links.filter(l => l.position === 'right').map(link => (
                            <NavLink key={link.label} href={link.link} active={link.active} activeClassName="navlink-active">
                                <a className={`block my-8 leading-none ${(isMobileMenuOpen || mode === 'black') ? 'text-black hover:text-black hover:border-black' : 'text-white hover:text-white hover:border-white'}   duration-100 border-transparent lg:mr-11 lg:pt-6 lg:pb-5 lg:inline-block lg:mt-0 lg:mb-0  lg:border-b-4 `}>
                                    {link.label}
                                </a>
                            </NavLink>
                        ))}
                    </div>
                    <RightButtons {...{ isLoggedIn, mode, isMobileMenuOpen }} />

                    <div className="self-center my-4 lg:my-0 lg:mx-4 ">
                        <LangSwitcher />
                    </div>
                </div>
            </nav>
        </>
    )
}


