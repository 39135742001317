import { useRouter } from 'next/router'
import { useEffect } from 'react';


const useOnRouteChangeCompleteCallback = ({ callback }: { callback: () => void }) => {
    const router = useRouter()
    useEffect(() => {
        const handleRouteChangeComplete = () => {
            callback()
        }

        router.events.on('routeChangeComplete', handleRouteChangeComplete);

        // If the component is unmounted, unsubscribe from the event
        return () => {
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
        };
    }, [callback])
}

export default useOnRouteChangeCompleteCallback;