import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

interface Value {
    error: { message: string; status: string };
    dispatchError: (message, status) => void;
    removeError: () => void;
}

const Context = createContext<Value | null>(null);


export const useModalError = (): Value => {
    const value = useContext(Context)
    if (value === null) throw new Error('no value provided')
    return value;
}

export const ErrorModalProvider = ({ children }: { children: ReactNode }) => {
    const [error, setError] = useState(null);

    const removeError = () => setError(null);

    const dispatchError = (message, status) => setError({ message, status });

    const value = useMemo(() => ({
        error,
        dispatchError,
        removeError
    }), [error]);

    return <Context.Provider value={value}>{children}</Context.Provider>

}