import Image, { ImageProps } from 'next/image'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const baseUrl = publicRuntimeConfig.websiteBaseUrl

type NextImageProps = ImageProps

const _Image: React.FC<NextImageProps> = ({ ...props }) => {
    // let _src = src
    // if (src.startsWith('/')) {
    //     _src = baseUrl + src;
    // }
    // return <Image src={_src} {...props} />

    return <img {...props} />
}

export default _Image