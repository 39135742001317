import Button from 'components/ui/button/Button';

interface Props {
    error: Error;
    componentStack: string | null;
    eventId: string | null;
    resetError(): void;
}
const ErrorFallback: React.FC<Props> = ({ error, resetError }) => {

    const errorMessage = (error && error.message) || 'Something went wrong'

    return (
        <div className="flex flex-col items-center justify-around max-w-lg mx-auto mt-20 ">
            <p>{errorMessage}</p>
            <Button className="mt-5" onClick={resetError}>Try again</Button>
        </div>
    )
}

export default ErrorFallback;