import React, { MouseEvent, PropsWithoutRef, RefAttributes, useImperativeHandle, useRef } from 'react';
import { CgSpinner } from 'react-icons/cg';
import { useButtonGroupContext } from '../button-group/button-group-context';
import { filterPropsWithGroup } from './utils';

interface Props {
    name?: string;
    // type?: React.ButtonHTMLAttributes<any>['type']
    // size?: NormalSizes
    // ghost?: boolean
    loading?: boolean
    // shadow?: boolean
    // auto?: boolean
    // effect?: boolean
    disabled?: boolean
    htmlType?: React.ButtonHTMLAttributes<any>['type']
    // icon?: React.ReactNode
    // iconRight?: React.ReactNode
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    className?: string
}

const defaultProps = {
    // type: 'default' as ButtonTypes,
    // size: 'medium' as NormalSizes,
    htmlType: 'button' as React.ButtonHTMLAttributes<any>['type'],
    // ghost: false,
    loading: false,
    // shadow: false,
    // auto: false,
    // effect: true,
    disabled: false,
    className: '',
}

type NativeAttrs = Omit<React.ButtonHTMLAttributes<any>, keyof Props>
export type ButtonProps = Props & typeof defaultProps & NativeAttrs

// export const Button: React.FC<any> = ({ children, onClick=() => {}, type = 'button' }) => {
const Button = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<ButtonProps>>(
    ({ ...btnProps }, ref: React.Ref<HTMLButtonElement | null>) => {

        const buttonRef = useRef<HTMLButtonElement>(null)
        useImperativeHandle(ref, () => buttonRef.current)

        const groupConfig = useButtonGroupContext()
        const filteredProps = filterPropsWithGroup(btnProps, groupConfig)
        const {
            name,
            children,
            disabled,
            onClick,
            htmlType,
            loading,
            className,
        } = filteredProps


        const clickHandler = (event: MouseEvent<HTMLButtonElement>) => {
            if (disabled || loading) return
            // const showDrip = !shadow && !ghost && effect
            // /* istanbul ignore next */
            // if (showDrip && buttonRef.current) {
            //     const rect = buttonRef.current.getBoundingClientRect()
            //     setDripShow(true)
            //     setDripX(event.clientX - rect.left)
            //     setDripY(event.clientY - rect.top)
            // }

            onClick && onClick(event)
        }

        return (
            <button className={`btn ${className}`}
                name={name}
                ref={buttonRef}
                disabled={disabled}
                type={htmlType}
                onClick={clickHandler}>

                <div className="flex justify-center">
                    <span>
                        {loading && (
                            <CgSpinner className="w-5 h-5 mr-5 animate-spin" />
                        )}
                    </span>
                    <span>
                        {children}
                    </span>
                </div>

            </button>
        )
    }
)


type ButtonComponent<T, P = {}> = React.ForwardRefExoticComponent<
    PropsWithoutRef<P> & RefAttributes<T>
>
type ComponentProps = Partial<typeof defaultProps> &
    Omit<Props, keyof typeof defaultProps> &
    NativeAttrs

Button.defaultProps = defaultProps

export default React.memo(Button) as ButtonComponent<HTMLButtonElement, ComponentProps>