import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { DefaultSeo as NextDefaultSeo, DefaultSeoProps } from 'next-seo'
import { OpenGraphImages } from 'next-seo/lib/types'
import getConfig from 'next/config'
import { getLocaleSuffix } from 'lib/seo/locales'

const { publicRuntimeConfig } = getConfig()
const baseUrl = publicRuntimeConfig.websiteBaseUrl

interface Props {
}
const DefaultSeo: React.FC<Props> = () => {

    const { t } = useTranslation();
    const { locale, defaultLocale, asPath } = useRouter()

    const title = t('seo:default.title')
    const description = t('seo:default.description')
    const logoImage = `${baseUrl}/images/opengraph/image_1.jpeg`
    const images: OpenGraphImages[] = [
        {
            url: logoImage,
            alt: 'Teacher Tribe',
            width: 1200,
            height: 630,
        }
    ]
    const localeSuffix = getLocaleSuffix(locale, defaultLocale)

    const seoProps: DefaultSeoProps = {
        defaultTitle: title,
        titleTemplate: `%s | ${t('seo:default.title')}`,
        description: t('seo:default.description'),
        canonical: `${baseUrl}${localeSuffix}${asPath}`,
        openGraph: {
            type: 'website',
            locale: locale,
            url: baseUrl,
            site_name: 'Teacher Tribe',
            images,
            description,
            title,
        },
        twitter:{
            site: '@ourteachertribe'
        },
        dangerouslySetAllPagesToNoIndex: (process.env.NODE_ENV !== 'production' || !!process.env.NEXT_PUBLIC_SEO_DISABLE),
        dangerouslySetAllPagesToNoFollow: (process.env.NODE_ENV !== 'production' || !!process.env.NEXT_PUBLIC_SEO_DISABLE),
        // twitter: {
        //     handle: '@handle', //@username for the content creator / author (outputs as twitter:creator)
        //     site: '@site', //@username for the website used in the card footer
        //     cardType: 'summary_large_image', //The card type, which will be one of summary, summary_large_image, app, or player
        // },
    }

    return (
        <NextDefaultSeo {...seoProps} />
    )
}

export default DefaultSeo