export enum Routes {
    HOME = '/',
    JOB_BOARD = '/job-board',
    TERMS_AND_CONDITIONS = '/docs/terms-and-conditions',
    ABOUT_US = '/docs/about-us',
    CONTACT = '/contact',
    CONTACT_CONFIRM = '/contact/confirm',
    PENDING_OFFER = '/job-offer/pending',
    OFFER = '/job-offer/offer',
    OFFER_CREATE = '/job-offer/create',
    OFFER_PREVIEW = '/job-offer/preview',
    OFFER_PAYMENT = '/job-offer/payment',
    OFFER_CREATE_CONFIRM = '/job-offer/confirm',
    OFFER_APPLY = '/job-offer/apply',
    OFFER_APPLY_CONFIRM = '/job-offer/confirm-application',
    OFFER_NOT_FOUND = '/job-offer/not-found',
    FAQ = '/faq',
    GLOSSARY = '/docs/glossary',
    JOIN_TEACHER_TRIBE = '/join-teacher-tribe',
    JOIN_TEACHER_TRIBE_CONFIRM = '/join-teacher-tribe/confirm',
}
