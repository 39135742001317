import type { AppProps } from 'next/app'
import '../styles/styles.css'
import { NavBar, NavBarProps } from '../components/navbar/NavBar'
// import { Amplify } from 'aws-amplify';
// import awsExports from '../aws-exports';
import { NavbarHeaderProvider } from '../components/navbar/NavbarHeaderContext'
import { RouteIndicator } from '../components/misc/RouteIndicator'
import DefaultSeo from '../components/seo/DefaultSeo';
import { init as initSentry } from '../lib/utils/sentry'
import * as Sentry from "@sentry/react";
import ErrorFallback from '../components/errors/ErrorFallback'
import Modal from 'react-modal';
import { Routes } from 'lib/routes'
import { ErrorModalProvider } from 'components/errors/error-modal-context'
import ErrorModal from 'components/errors/ErrorModal'
import { appWithTranslation, useTranslation } from 'next-i18next'
import 'intersection-observer';

initSentry();

// Amplify.configure({
//   ...awsExports,
//   ssr: true,
// })

Modal.setAppElement('#__next')


function MyApp({ Component, pageProps, err }: AppProps & { err: any }) {

  const { t } = useTranslation()

  const navBarProps: NavBarProps = {
    isLoggedIn: false,
    links: [
      // {
      //   label: t('common:navbar.glossary'),
      //   link: Routes.GLOSSARY,
      //   position: 'right'
      // },
      {
        label: t('common:navbar.jobBoard'),
        link: Routes.JOB_BOARD,
        position: 'right'
      },
      {
        label: t('common:navbar.faq'),
        link: Routes.FAQ,
        position: 'right'
      },
      {
        label: t('common:navbar.contact'),
        link: Routes.CONTACT,
        position: 'right'
      },
    ]
  }

  return (
    <>
      <DefaultSeo />
      <ErrorModalProvider>
        <NavbarHeaderProvider>
          <RouteIndicator />
          <NavBar {...navBarProps} />
          <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <Component {...pageProps} err={err} />
          </Sentry.ErrorBoundary>
        </NavbarHeaderProvider>
        <ErrorModal />
      </ErrorModalProvider>
    </>
  )
}

export default appWithTranslation(MyApp)
