import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import ReactModal from 'react-modal';

const MAXWIDTHS = {
    'xs': 'max-w-md',
    'sm': 'max-w-2xl',
    'md': 'max-w-4xl',
    'lg': 'max-w-6xl',
}
const BOTTOMS = {
    'xs': '',
    'sm': '',
    'md': 'bottom-1/4',
    'lg': 'bottom-8',
}
interface Props {
    isOpen: boolean;
    title: string;
    onRequestClose?: () => void;
    size?: 'xs' | 'sm' | 'md' | 'lg';
}

const Modal: React.FC<PropsWithChildren<Props>> = ({
    children,
    isOpen = false,
    title,
    onRequestClose,
    size = 'lg'
}) => {

    const [modalIsOpen, setModalIsOpen] = useState(isOpen);

    useEffect(() => {
        setModalIsOpen(isOpen)
    }, [isOpen])

    function closeModal() {
        setModalIsOpen(false);
        onRequestClose && onRequestClose()
    }
    const modalContentRef = useRef();
    useOnClickOutside(modalContentRef, closeModal);

    const maxWidth = MAXWIDTHS[size];
    const bottom = BOTTOMS[size];

    return (
        <ReactModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel={title}
            overlayClassName="fixed z-40 inset-0 bg-black bg-opacity-30"
            className={`absolute w-11/12 ${maxWidth} outline-none left-1/2 top-8 ${bottom}`}
        >
            <div ref={modalContentRef} className="relative h-full overflow-auto bg-white shadow -left-1/2 rounded-xl">
                <div className="relative top-0 px-4 py-4 bg-white md:px-10 md:py-10">
                    <span onClick={closeModal} className="absolute top-0 right-0 px-2 py-2 mx-2 my-2 rounded-full cursor-pointer hover:bg-gray-200">
                        <IoMdClose className="text-4xl text-gray-800" />
                    </span>
                    <h1 className="px-10 text-xl font-bold text-center">{title}</h1>
                </div>
                <div className="">
                    {children}
                </div>
            </div>
        </ReactModal>
    )
}

export default Modal;