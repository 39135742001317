import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

interface Value {
    navbarHeaderRef: (node?: Element) => void;
    isNavbarHeaderVisible: boolean,
    mode: 'black' | 'white',
    setMode: (mode: 'black' | 'white') => void,
}

const Context = createContext<Value | null>(null)

export const useNavbarHeader = (): Value => {
    const value = useContext(Context)
    if (value === null) throw new Error('no value provided')
    return value;
}

export const NavbarHeaderProvider = ({ children }: { children: ReactNode }) => {
    const [navbarHeaderRef, isNavbarHeaderVisible] = useInView({
        threshold: 0.01
    });
    const [mode, setMode] = useState<Value['mode']>('black')

    const value = useMemo(() => ({
        navbarHeaderRef,
        isNavbarHeaderVisible,
        mode,
        setMode
    }), [navbarHeaderRef, isNavbarHeaderVisible, mode, setMode])
    
    return <Context.Provider value={value}>{children}</Context.Provider>
}